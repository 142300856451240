$tm-color-blue: #1da1f2;
$tm-color-orange: #ea8804;
$tm-color-green: #36b37e;
$tm-color-red: #ff5e5e;
$tm-color-purple: #551fff;

$tm-color-medium-blue: #eaf9ff;

$tm-color-light-blue: #f7f9fc;
$tm-color-light-orange: #fff2e9;
$tm-color-light-green: #ebf7f2;
$tm-color-light-red: #ffefef;
$tm-color-light-purple: #ebe7f7;

$tm-color-text-primary: #1f3965;
$tm-color-text-secondary: #6b82ab;

$tm-color-dark-gray: #dbe2ea;
$tm-color-light-gray: #f2f4f8;
$tm-color-white: #ffffff;

/* Krayon V1 colors */
$primary-primary__110: #0C85CF;
$primary-primary__100: #1DA1F2;
$primary-primary__90: #4DB5F5;
$primary-primary__80: #7DC9F8;
$primary-primary__70: #ADDDFA;
$primary-primary__60: #DDF1FD;

$neutrals-grey__100: #212427;
$neutrals-grey__90: #3F3F41;
$neutrals-grey__80: #5B5B5D;
$neutrals-grey__70: #76787A;
$neutrals-grey__60: #949494;
$neutrals-grey__50: #AFAFB1;
$neutrals-grey__40: #CCCCCC;
$neutrals-grey__30: #E8E8E8;
$neutrals-grey__20: #F4F4F4;
$neutrals-grey__10: #FFFFFF;

$semantic-basic__110: #050505;
$semantic-basic__100: #1F1F1F;
$semantic-basic__10: #E9E9E9;
$semantic-information__110: #0072C3;
$semantic-information__100: #0072C3;
$semantic-information__10: #E5F6FF;
$semantic-error__110: #DE3333;
$semantic-error__100: #FF6666;
$semantic-error__10: #FFEBEB;
$semantic-warning__110: #C88913;
$semantic-warning__100: #EAA624;
$semantic-warning__10: #FDF2E0;
$semantic-success__110: #4A9C52;
$semantic-success__100: #65B66D;
$semantic-success__10: #F0F8F0;

$pastels-feijoa__110: #8BC96E;
$pastels-feijoa__100: #A8D793;
$pastels-feijoa__50: #D4EBC9;
$pastels-feijoa__10: #E9F5E4;
$pastels-fringy__110: #82CFB3;
$pastels-fringy__100: #A7DDC9;
$pastels-fringy__50: #D3EEE4;
$pastels-fringy__10: #E9F6F1;
$pastels-melaine__110: #CB81C8;
$pastels-melaine__100: #DAA6D8;
$pastels-melaine__50: #ECD2EB;
$pastels-melaine__10: #F5E8F5;
$pastels-biloba__110: #987FE1;
$pastels-biloba__100: #B8A7EA;
$pastels-biloba__50: #DBD3F4;
$pastels-biloba__10: #EDE9F9;
$pastels-ash__110: #B5A997;
$pastels-ash__100: #CAC2B5;
$pastels-ash__50: #E4E0DA;
$pastels-ash__10: #F1EFEC;
$pastels-calico__110: #D4A868;
$pastels-calico__100: #E0C091;
$pastels-calico__50: #EFDFC8;
$pastels-calico__10: #F7EFE3;
$pastels-sea_pink__110: #EB7160;
$pastels-sea_pink__100: #F19A8E;
$pastels-sea_pink__50: #F8CCC6;
$pastels-sea_pink__10: #FBE5E2;
$pastels-morning_glory__110: #6EC2D4;
$pastels-morning_glory__100: #94D2DF;
$pastels-morning_glory__50: #C9E8EF;
$pastels-morning_glory__10: #E4F3F7;
$pastels-polo_blue__110: #6E9DC9;
$pastels-polo_blue__100: #93B6D7;
$pastels-polo_blue__50: #C9DAEB;
$pastels-polo_blue__10: #E4ECF5;

$base-dark: #2F3C51;
$base-dark__110: #232E40;
$base-dark__100: #2F3C51;
$base-dark__90: #434F62;
$base-dark__80: #3E4C63;
$base-dark__50: #5B6A83;
$base-light: #FFFFFF;