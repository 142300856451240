@import 'variables';

.cont {
	position: relative;
}

.ldsRing {
	display: inline-block;
	position: absolute;
	width: 2.5rem;
	height: 2.5rem;
	background: white;
	border-radius: 50%;
	box-shadow: 0px -1px 18px rgba(0, 0, 0, 0.2);
	top: 50%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 10;
}
.ldsRing div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 1.8rem;
	height: 1.8rem;
	margin: 0.35rem;
	border: 0.3rem solid #fff;
	border-radius: 50%;
	animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: $tm-color-blue transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
	animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
	animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes ldsRing {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
